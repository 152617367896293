import React from 'react'
import Link from 'next/link'
import styles from '../../styles/Home.module.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const CommonButton = ({ header, style, Text, image, login, link, target }) => {
  const size = '25px'

  return (
    <Link href={link ? link : '/'} target={target && '_blank'} style={{ textDecoration: 'none' }}>
      {login ? (
        <div className={styles.outlineLoginBtn}>
          <div style={{ flexDirection: 'row', display: 'flex', margin: 0 }}>
            <p style={{ margin: 0, alignItems: 'center' }}>Login</p>
          </div>
        </div>
      ) : (
        <div className={header ? styles.outlineHeaderBtn : styles.outlineButton} style={style && style}>
          <div style={{ flexDirection: 'row', display: 'flex', margin: 0, alignItems:"center" }}>
            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/WebHRwhite.svg" style={{ marginRight: 8, width:size, height:size }} alt="signup webhr" />
            <p style={{ margin: 0, alignItems: 'center', marginTop: 2 }}>{Text}</p>
          </div>
        </div>
      )}
    </Link>
  )
}

export default CommonButton
