import React, { useState, useEffect } from 'react'
import { Button, Col, Container, Dropdown, Row, NavDropdown } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Navbar } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import CommonButton from './commonButton'
import styles from '../../styles/Home.module.css'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useWindowSize } from '@/hooks/WindowSize'
import {ToastContainer } from 'react-toastify'
import Image from 'next/image'
const NewHeader = () => {
  const [url, setUrl] = useState({ url: '', error: false })
  const { width } = useWindowSize()
  const [productDropdown, setProductDropdown] = useState(false)
  const [aboutDropdown, setAboutDropdown] = useState(false)
  const [resourcesDropdown, setResourcesDropdown] = useState(false)
  const [navExpanded, setNavExpanded] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [screenWidth, setScreenWidth] = useState();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeNav = () => {
    setNavExpanded(false)
    setAboutDropdown(false)
    setProductDropdown(false)
    setResourcesDropdown(false)
    setLoginModal(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (url !== '') {
      window.open(`https://${url.url}.webhr.co/`, '_blank')
      setUrl('')
    }
  }

  const router = useRouter()
  const [email, setEmail] = useState('')

  const onSubmit = (e) => {
    setResourcesDropdown(false)
    e.preventDefault()
    router.push({
      pathname: '/demo',
      query: {
        em: email,
      },
    })
  }

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  const openTabInMob = (tabname, condition)=>{
    switch (tabname) {
      case "product":
        setProductDropdown(true)
        setResourcesDropdown(false)
        setAboutDropdown(false)
        break;
      case "resources":
        setProductDropdown(false)
        setResourcesDropdown(true)
        setAboutDropdown(false)
        break;
        case "company":
        setProductDropdown(false)
        setResourcesDropdown(false)
        setAboutDropdown(true)
        break;
      
      
      default:
        break;
    }

  }

  const ButtonCommon = dynamic(() => import('@/components/Common/WhiteButton'))
  const MobileHeader = dynamic(() => import('@/components/Common/MobileHeader'))
  const size = '40px'

  return (
    // <>
    <div className={styles.NewHeaderheader}>
      <Container>
        <Navbar expanded={navExpanded} sticky="top" expand="lg">
          <Link href="/"  onClick={closeNav}>
              <Image src="/WebHR.svg" alt='webhr' width={135} height={60} />
          </Link>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setNavExpanded(!navExpanded)} />
          <Navbar.Collapse id="navbarScroll" onMouseLeave={() => setProductDropdown(false) }>
            <Nav className="me-auto my-2 my-lg-0" style={{ flex: 1, marginLeft: 20 }} navbarScroll>
              <Dropdown show={productDropdown} className={styles.newDropdownStyles}>
                <Dropdown.Toggle className={styles.dropdownStyleNew} onMouseEnter={() => openTabInMob("product")}>
                  Product
                </Dropdown.Toggle>

                <Dropdown.Menu  className={styles.headerDropdown} style={{marginTop:22, width:"70em", minWidth:"100%", overflowY:"auto"}} onMouseEnter={() => setProductDropdown(true)} onMouseLeave={() => setProductDropdown(false)}>
                  <Row style={{backgroundColor:"#fff"}} >
                   <Col lg={2}>
                    <Col lg={12} sm={3} md={3} style={{ paddingBlock: 10, marginTop:'10px' }}>
                      <Link className={styles.navLinks} href="/about" style={{ paddingInline: 10 }} onClick={closeNav}>
                        What is WebHR?
                      </Link>
                    </Col>
                    <Col lg={12} sm={3} md={3} style={{ paddingBlock: 10 }} >
                      <Link className={styles.navLinks} href="/integrations" style={{ paddingInline: 10 }} onClick={closeNav}>
                        Integrations
                      </Link>
                    </Col>
                    <Col lg={12} sm={3} md={3} style={{ paddingBlock: 10 }}>
                      <Link className={styles.navLinks} href="/security" style={{ paddingInline: 10 }} onClick={closeNav}>
                        Data Security
                      </Link>
                    </Col>
                    <Col lg={12} sm={3} md={3} style={{ paddingBlock: 10 }}>
                      <Link className={styles.navLinks} href="/clients" style={{ paddingInline: 10 }} onClick={closeNav}>
                        Clients
                      </Link>
                    </Col>
                    <Col lg={12} sm={3} md={3} style={{ paddingBlock: 10 }}>
                      <Link className={styles.navLinks} href="/faqs" style={{ paddingInline: 10 }} onClick={closeNav}>
                        FAQs
                      </Link>
                    </Col>
                    <Col lg={12} sm={3} md={3} style={{ paddingBlock: 10 }}>
                      <Link className={styles.navLinks} href="/mobile" style={{ paddingInline: 10 }} onClick={closeNav}>
                        Mobile Apps
                      </Link>
                    </Col>
                  </Col> 
                  <Col  style={{ paddingBlock: 20 }} onMouseEnter={() => setProductDropdown(true)} onMouseLeave={() => setProductDropdown(true)}>
                    {/* <div className={styles.headerBorder}></div> */}
                    <h4 style={{ color: '#ADB5BD' }}>WebHR Modules</h4>
                    <Row >
                      <Col xl="4" lg="5" md="5" sm="12">
                        <Link href="/modules/core-hr" style={{ textDecoration: 'none' }} onClick={closeNav}>
                          <div className={styles.navbarModules}>
                            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/core_hr.svg" height={20} style={{ marginRight: 15 }} />
                            <div>
                              <h5>Core HR</h5>
                              <p>
                                Multi-user role based system, document management, Comprehensive report and <br /> graphs.
                              </p>
                            </div>
                          </div>{' '}
                        </Link>
                      </Col>
                      <Col xl="4" lg="5" md="5"  sm="12">
                        <Link href="/modules/performance" style={{ textDecoration: 'none' }} onClick={closeNav}>
                          <div className={styles.navbarModules}>
                            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/performance.svg" height={20} style={{ marginRight: 15 }} />
                            <div>
                              <h5>Performance</h5>
                              <p>Boost employee performance with our intuitive Performance Module. Drive success through goals and real-time feedback.</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xl="4" lg="5" md="5"  sm="12">
                        <Link href="/modules/payroll" style={{ textDecoration: 'none' }} onClick={closeNav}>
                          <div className={styles.navbarModules}>
                            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/payroll.svg" height={22} style={{ marginRight: 15 }} />
                            <div>
                              <h5>Payroll</h5>
                              <p>Automate your payroll process and seamlessly oversee employee payroll with our extensive Payroll Module.</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xl="4" lg="5" md="5"  sm="12">
                        <Link href="/modules/time-and-attendance" style={{ textDecoration: 'none' }} onClick={closeNav}>
                          <div className={styles.navbarModules}>
                            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/time_attendance.svg" height={22} style={{ marginRight: 15 }} />
                            <div>
                              <h5>Time & Attendance</h5>
                              <p>Employees can track time anywhere using an Android or iOS mobile phone, computer, and tablet by clock in and out.</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xl="4" lg="5" md="5"  sm="12">
                        <Link href="/modules/recruitment" style={{ textDecoration: 'none' }} onClick={closeNav}>
                          <div className={styles.navbarModules}>
                            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/recruitment.svg" height={22} style={{ marginRight: 15 }} />
                            <div>
                              <h5>Recruitment</h5>
                              <p>From creating job posts to onboarding top talent, our recruitment module makes hiring a breeze.</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                      <Col xl="4" lg="5" md="5"  sm="12">
                        <Link href="/modules" style={{ textDecoration: 'none' }} onClick={closeNav}>
                          <div className={styles.navbarModules}>
                            <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/base_modules.svg" height={22} style={{ marginRight: 15 }} />

                            <div>
                              <h5>WebHR Modules</h5>
                              <p>WebHR is an All-In-One Social HR Software, we offer more than 45+ modules. Click here to develop deeper understanding.</p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                  </Row>
                </Dropdown.Menu>
              </Dropdown>
                <Nav.Link className={`${styles.navLinks}`} style={{marginTop:"3px", height:"100%", paddingLeft:"0px", marginLeft:"0px"}} href="/pricing">
                  Pricing & Signup
                </Nav.Link>
                <Nav.Link className={`${styles.navLinks}`} style={{marginTop:"3px", height:"100%", paddingLeft:"0px", marginLeft:"0px" }}  href="/demo">
                  Free Demo
                </Nav.Link>
              <Dropdown show={resourcesDropdown}>
                <Dropdown.Toggle className={styles.dropdownStyleNew} onMouseEnter={() => openTabInMob("resources")} onMouseLeave={() => setResourcesDropdown(false)}>
                  Resources
                </Dropdown.Toggle>
                <Dropdown.Menu  style={{width:"37em", marginBottom:10}} className={styles.aboutSideDropdown} onMouseEnter={() => setResourcesDropdown(true)} onMouseLeave={() => setResourcesDropdown(false)}>
                  <Row style={{ width:"100%", margin:"0px" }}>
                    <Col lg={4} style={{ marginTop: '0', paddingTop:10 }}>
                      <Link className={styles.navLinks} href="/blog" style={{ padding: 10 }} onClick={closeNav}>
                        Blog
                      </Link>
                      <Link className={styles.navLinks} href="/glossary" style={{ padding: 10 }}  onClick={closeNav}>
                        HR Glossary
                      </Link>
                      <Link className={styles.navLinks} href="/contents" style={{ padding: 10 }}  onClick={closeNav} >
                        Content Library
                      </Link>
                    </Col>
                    {/* styles.headerAboutSide */}
                    <Col className={styles.headerAboutSide } style={{ backgroundColor: '#FCF1BD',}}> 
                      <Row style={{}}>
                        <h5 style={{  color: '#6C757D' }}>Subscribe to our newsletter!</h5>
                      </Row>
                      <Row style={{ justifyContent: 'center', display: 'flex' }}>
                        <LazyLoadImage
                          src="https://cdn.webhr.co/Website/images/components/resources_Illustration.png
                            "
                          className={styles.aboutUsIllustration}
                        />
                      </Row>
                      <Row>
                        <Form onSubmit={onSubmit} style={{marginTop: 20 }}>
                          <Row>
                            <Col lg={12} xxl={8} style={{ marginTop:7 }}>
                              <Form.Group style={{ }}>
                                <Form.Control autoComplete="off" name="ea" type="text" style={{ width:"100%"}} value={email.ea} placeholder="Email Address" className={styles.commonDemoEmail} onChange={onChange} />
                              </Form.Group>
                            </Col>
                            <Col lg={12} xxl={4} style={{ textDecoration: 'none', marginTop:7 }}>
                              <Button onClick={onSubmit} type="submit" className={styles.areebaHover}>
                                Subscribe
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                        <p style={{ marginTop: 22, color: '#6C757D' }}>We’ll send you the best of our blogs, Subscribe for newsletter and stay connected.</p>
                      </Row>
                    </Col>
                  </Row>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown show={aboutDropdown}>
                <Dropdown.Toggle className={styles.dropdownStyleNew} onMouseEnter={() => openTabInMob("company")} onMouseLeave={() => setAboutDropdown(false)}>
                  Company
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ marginBottom:10, width:"37em"}} className={styles.aboutSideDropdown} onMouseEnter={() => setAboutDropdown(true)} onMouseLeave={() => setAboutDropdown(false)}>
                  <Row style={{ margin:0, padding:0, width:"100%", borderWidth:2, borderColor:"#000" }}>
                    <Col lg={4}>
                      <Link className={styles.navLinks} href="/careers" style={{ paddingBlock: 10, marginTop:'0px' }} onClick={closeNav} >
                        Careers
                      </Link>
                      <Link className={styles.navLinks} href="/privacy" style={{ paddingBlock: 10, marginTop:'0px' }}  onClick={closeNav}>
                        Privacy Policy
                      </Link>
                      <Link className={styles.navLinks} href="/termsconditions" style={{ paddingBlock: 10, marginTop:'0px' }} onClick={closeNav}>
                        Terms & Conditions
                      </Link>
                      <Link className={styles.navLinks} href="/gdpr" style={{  paddingBlock:10  }}  onClick={closeNav} >
                        GDPR
                      </Link>
                      <Link className={styles.navLinks} href="/partner-with-us" style={{ paddingBlock: 10, marginTop:'0px' }} onClick={closeNav}>
                        Partner with Us
                      </Link>
                      <Link className={styles.navLinks} href="/contact" style={{ paddingBlock: 10, marginTop:'0px' }} onClick={closeNav}>
                        Contact Us
                      </Link>
                    </Col>
                    <Col lg=""  className={styles.headerAboutSide}>
                      <Row>
                        <Col lg={12} className={"d-flex"} style={{ }}>
                          <LazyLoadImage
                            src="https://cdn.webhr.co/Website/images/components/webHR_icon.svg
                              "
                            className={styles.aboutUsIllustration}
                            style={{ marginRight: 10, }}
                          />
                          <h5 style={{ paddingTop: 10, color: '#6C757D' }}>Partner with WebHR</h5>
                        </Col>
                        <Col lg={12} style={{  }}>
                          <Row>
                            <LazyLoadImage
                              src="https://cdn.webhr.co/Website/images/components/aboutus_illustration.png
                                "
                              style={{}}
                              className={styles.aboutUsIllustration}
                            />
                          </Row>
                        </Col>
                        <Col lg={12} style={{ marginTop: 20 }}>
                          <Row>
                            <Col sm={12} lg={6} style={{marginTop:5}} >
                              <Link onClick={() => setAboutDropdown(false)} href={'/partner-with-us/partnership-program#partnerCommunityForm'} style={{ textDecoration: 'none', marginTop: 2 }}>
                                <Button className={styles.partnerButton} style={{}} onClick={closeNav}>
                                  Partnership Program
                                </Button>
                              </Link>
                            </Col>
                            <Col sm={12} lg={6}  style={{marginTop:5}} >
                              <ButtonCommon onClick={() => {
                                setAboutDropdown(false)
                                closeNav()
                              }} 
                              blue 
                              text={'Affiliate Program'} 
                              link={'/partner-with-us/affiliate-apply'} 
                              style={{ width: "100%", margin: 0, padding: 10 }} 
                            />
                            </Col>
                            <Col lg={12}>
                              <p style={{ marginTop: 22, color: '#6C757D' }}>Be a part of #1 HR software around the globe for small and medium enterprises.</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
            {/* <Form className="d-flex" style={{ alignItems: 'center' }}> */}
              <a href="tel:+1-800-801-4801" style={{ color: '#52606D', textDecoration: 'none' }}>
                <p style={{ marginTop: 20, marginInline: 10, color: '#6C757D' }}> (+1)-800-801-4801</p>
              </a>
              <Dropdown show={loginModal}>
                <Dropdown.Toggle onMouseEnter={() => setLoginModal(true)} onMouseLeave={() => setLoginModal(false)} className={styles.outlineLoginBtn} style={{ backgroundColor: 'white' }}>
                  Login
                </Dropdown.Toggle>
                {loginModal && (
                  <Dropdown.Menu className={styles.dropdownMenu3} onMouseEnter={() => setLoginModal(true)} onMouseLeave={() => setLoginModal(false)} >
                    <ToastContainer position="bottom-left" toastStyle={{ backgroundColor: 'red' }} autoClose={2500} hideProgressBar newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover={false} />
                    <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/sheild.svg" />
                    <h3 style={{ marginTop: 20, marginBottom: 0 }}>Welcome Back,</h3>
                    <p style={{ color: '#ADB5BD', padding: 0, fontSize: 13 }}>Please enter your designated Instance URL.</p>
                    <Form name='urlForm' onSubmit={handleSubmit}>
                      <div style={{ flexDirection: 'row', display: 'flex', color: '#ADB5BD', marginBottom: 10 }}>
                        <Form.Label column lg={2}>
                          https://
                        </Form.Label>
                        <Col lg={7} style={{ marginInline: 10 }}>
                          <Form.Control style={{ borderRadius: 50, paddingInline: 15, borderColor: url.error ? 'red' : '' }} name={"url"} autoFocus value={url.url} onChange={(e) => setUrl({ ...url, url: e.target.value })} type="text" placeholder="Enter your domain" />
                        </Col>
                        <Form.Label column lg={2}>
                          .webhr.co/
                        </Form.Label>
                      </div>
                      <Button className={styles.primaryBtn} onClick={handleSubmit} type="submit">
                        Login
                      </Button>
                      <div className={styles.loginGoogleRow}>
                        <Link href={'/google-login'} target="_blank" className={styles.googleLoginModal} >
                          <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/google.svg" />
                        </Link>
                        <Link href={'/login'} target="_blank" className={styles.googleLoginModal}>
                          <LazyLoadImage src="https://cdn.webhr.co/Website/images/components/azure.svg" />
                        </Link>
                      </div>
                    </Form>
                  </Dropdown.Menu>
                )}
              </Dropdown>
              {/* <CommonButton login header /> */}
              <CommonButton link={'/pricing'} image header Text={'Signup for free'} />
            {/* </Form> */}
          </Navbar.Collapse>
        </Navbar>
      </Container>
     </div>


    // </>
  )
}
export default NewHeader
